/* eslint-disable */

import React from 'react';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Title = styled.h1`
  font-size: 1.375rem;
  font-weight: 500;
`;

const Highlight = styled.p`
  font-weight: 500;
`;

function CopyrightPage() {
  return (
    <Layout>
      <SEO title="Copyright" />
      <Title>Bản quyền</Title>

      <ol>
        <li>
          Các video được đăng tải trên website trang.edu.vn đều thuộc bản quyền và quyền sử dụng của
          Công ty cổ phần Video Giáo Dục Trạng.
        </li>
        <li>
          Toàn bộ nội dung của trang web được bảo vệ bởi luật bản quyền của Việt Nam và các công ước
          quốc tế.
        </li>
      </ol>
      <br />

      <Title>Quyền sử dụng video</Title>

      <ol>
        <li>
          Được phép xem/trình chiếu video để giảng dạy và học tập với mục đích phi thương mại trực
          tiếp trên website <a href="/">https://trang.edu.vn</a>.
        </li>
        <li>
          Phản hồi và khiếu nại nếu video có sự sai phạm về bản quyền và độ chính xác của thông tin.
        </li>
      </ol>
      <br />

      <Title>
        <b>KHÔNG</b>
{' '}
được phép
</Title>

      <ol>
        <li>Tải/sao chép video dưới mọi hình thức.</li>
        <li>
          Thương mại/chia sẻ/phát tán/tặng video cho bất kỳ cá nhân nào và lên các trang mạng xã hội
          như Facebook, Youtube, Twitter, … nếu
{' '}
<b>KHÔNG</b>
          {' '}
có sự cho phép bằng văn bản của chúng
          tôi.
        </li>
      </ol>

      <p style={{ color: 'red' }}>
        <b>Cảnh báo:</b> Video không tránh khỏi những sai sót, rất mong quý khách chọn lọc kỹ thông
        tin khi xem và sử dụng video để giảng dạy và phản hồi cho chúng tôi qua email
        <a href="mailto:videogiaoduc@trang.edu.vn">videogiaoduc@trang.edu.vn</a>
{' '}
hoặc hotline
<a href="tel:0909910583">0909910583</a>.
      </p>

      <Highlight>Trân trọng!</Highlight>
    </Layout>
  );
}

export default CopyrightPage;
